/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax } from "gsap";

export default {
    init: (app, Genius, Swiper) => {
        /*
		|
		| Constants
		|-----------
        */
        const
            isDesktop             = $('body').hasClass('isDesktop'),
            $sectionSliderDesktop = $('#section-slider-desktop'),
            $homeSliderWrapper    = $sectionSliderDesktop.find('.genius-home'),
            $homeSliderPagination = $sectionSliderDesktop.find('.genius-home-pagination'),
            $homeSliderArrows     = $sectionSliderDesktop.find('.genius-home-arrows'),
            $loadbar              = $sectionSliderDesktop.find('.genius-loader'),
            $sectionSliderMobile  = $('#section-slider-mobile'),
            $swiperHome           = $sectionSliderMobile.find('.swiper-home .swiper-container')
        ;


        /*
		|
		| Desktop
		|-----------
        */
        if (isDesktop && $(window).width() > 1200){
            $sectionSliderMobile.hide();
            /*
            |
            | Register slide images dimensions
            |-----------------------------------
            */
            let slideImages = {};

            $.each($homeSliderWrapper.find('.genius-slide'), function(index, value){
                const $slide = $(this);

                const left = $slide.find('.item-img').offset().left - $slide.offset().left;
                const width = $slide.find('.item-img').outerWidth();

                slideImages[`slide-${index}`] = {
                    left: left,
                    width: width
                }
            });

            /*
            |
            | Genius
            |---------
            */
            let geniusHome = new Genius($homeSliderWrapper, {
                mousewheel: true,
                autoplay: true,
                delay: 6000,
                loadbar: $loadbar,
                pagination: $homeSliderPagination,
                navigation: {
                    prev: $homeSliderArrows.find('.item-prev'),
                    next: $homeSliderArrows.find('.item-next')
                },
                timelineEnter: function ($slide, timeline, index) {
                    const $layer         = $('.layer');
                    const $itemImg       = $slide.find('.item-img');
                    const $itemTitle     = $slide.find('.item-title');
                    const $itemText      = $slide.find('.item-text');
                    const $itemLink      = $slide.find('.item-link');
                    const staggerContent = [$itemTitle, $itemText, $itemLink];

                    timeline
                        .add(TweenMax.to(staggerContent, 0.01, { autoAlpha: 0 }), 'start')
                        .add(TweenMax.to($itemImg, 0.01, { autoAlpha: 0 }), 'start')
                        .add(TweenMax.staggerFromTo(staggerContent, 1, { y: 20 }, { y: 0, autoAlpha: 1, ease: Power1.easeOut }, 0.15), 'start+=0.4')
                        .add(TweenMax.to($itemImg, 1, { autoAlpha: 1, ease: Power1.easeOut }), '-=1')
                },
                timelineLeave: function ($slide, timeline, index) {
                    const $layer         = $('.layer');
                    const $itemImg       = $slide.find('.item-img');
                    const $itemTitle     = $slide.find('.item-title');
                    const $itemText      = $slide.find('.item-text');
                    const $itemLink      = $slide.find('.item-link');
                    const staggerContent = [$itemTitle, $itemText, $itemLink];

                    timeline
                        .add(TweenMax.staggerTo(staggerContent, 1, { y: -20, autoAlpha: 0, ease: Power1.easeOut }, 0.15), 'start')
                        .add(TweenMax.to($itemImg, 0.8, { autoAlpha: 0, ease: Power4.easeOut }), 'start')
                },
            });


            /*
            |
            | Genius Event (handling layer's movement)
            |-------------------------------------------
            */
            $homeSliderWrapper.on('genius:update', function(e){
                const { activeSlide, leavingSlide, index, leavingIndex } = e.genius;
                const $layer   = $('.layer');

                $homeSliderWrapper.on(`genius:slide${leavingIndex}Leave`, function () {
                    const $leavingImg = leavingSlide.find('.item-img');
                    const color       = activeSlide.data('color');
                    const slide       = slideImages[`slide-${index}`];

                    TweenMax.set($layer, { width: $leavingImg.outerWidth() });
                    TweenMax.to($layer, 0.6, { backgroundColor: color, ease: Power4.easeOut, delay: 0 });
                    TweenMax.to($layer, 1, { x: slide.left, width: slide.width, ease: Power4.easeInOut, delay: 0.6 })
                });
            });
        }

        /*
		|
		| isMobile
		|-----------
        */
        else{
            $sectionSliderDesktop.hide();

            let swiperTimelines = [];
            let prevSlideIndex = 0;
            $.each($swiperHome.find('.swiper-slide'), function(){
                const $slide = $(this);
                const timeline = new TimelineMax({ paused: true });
                const splitTitle = new SplitText($slide.find('.item-title-text'), {type: 'words, chars'});

                timeline
                    //.from($slide.find('.item-img'), 0.8, { autoAlpha: 0, ease: Power1.easeOut }, 'start')
                    .from($slide.find('.item-title'), 1, { scaleX: 0, transformOrigin: 'right top', ease: Expo.easeInOut }, 'start')
                    .staggerFrom(splitTitle.chars, 1, {y: 15, autoAlpha: 0, ease: Power1.easeOut}, 0.05, '-=0.3')

                swiperTimelines.push(timeline);
            });


            let swiperHome = new Swiper($swiperHome, {

                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    init: function () {
                       swiperTimelines[this.realIndex].play()
                    },
                    slideChangeTransitionStart: function () {

                    }
                }
            })

            swiperHome.init();



            swiperHome.on('slideChange', function(){
                swiperTimelines[swiperHome.previousIndex].reverse().timeScale(2)
                swiperTimelines[swiperHome.realIndex].play().timeScale(1)
            })

        }


    }
}
