/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, OpenObject, SliderHome, InitMaps, Swiper, Scrollbar, MobileDetect) => {

        /*
		|
		| Constants
		|-----------
        */
        const
            $body             = $('body'),
            $affectedByMobile = $('body'),
            $siteContainer    = $('.site-container'),
            $pageLoader       = $('.page-loader'),
            $pageLoadbar      = $pageLoader.find('.loadbar'),
            $header           = $('.header'),
            $logo             = $header.find('.logo-container > a'),
            $menuBtn          = $header.find('.btn-menu'),
            $menuBtnBar       = $menuBtn.find('.item-burger > span'),
            $navContainer     = $('.nav-container'),
            $menuContainer    = $navContainer.find('.menu-container'),
            $menuLi           = $menuContainer.find('.menu > li'),
            $menuLinks        = $menuLi.find('> a'),
            $subMenuContainer = $navContainer.find('.sub-menu-container'),
            scrollAreas       = document.querySelectorAll('.scroll-area')
        ;

        /*
		|
		| Variables
		|-----------
        */
        let
            isMobile = null
        ;


        /*
		|
		| Mobile detect
		|----------------
		*/
        let mobileDetector = new MobileDetect();
        isMobile = mobileDetector.isMobile();
        if (isMobile == null) {
            $affectedByMobile.addClass('isDesktop');
        } else {
            $affectedByMobile.addClass('isMobile');
        }


        /*
		|
		| Loader
		|---------
		*/
        let loaderTl = new TimelineMax({
            paused: true,
            onComplete: function () {
                app.dispachEvent($body, 'loaderEnd');
                $pageLoader.remove();
            }
        });

        TweenMax.from($pageLoader.find('.item-logo'), 1, { y: +30, autoAlpha: 0, ease: Power1.easeOut })

        loaderTl
            .fromTo($pageLoadbar.find('.item-inner'), 1, { scaleX: 0.5 }, { scaleX: 1,  ease: Power1.easeOut }, 'start')
            .to($pageLoader.find('.item-logo'), 1, { y: -30, autoAlpha: 0, ease: Power1.easeOut }, 'dodo-=0')
            .to($pageLoader.find('.item-icon'), 1, { y: 30, autoAlpha: 0, ease: Power1.easeOut, onComplete: () => app.dispachEvent($body, 'loaderAlmostEnd') }, 'dodo-=0')
            .to($pageLoader, 0.8, { autoAlpha: 0, ease: Power1.easeOut }, '-=0.2')

        $(window).on('load', function () {
            loaderTl.play();
        });


        /*
		|
		| Menu
		|-------
		*/
		const menu = new Menu($menuContainer, $menuBtn, { reverseTimeScale: 1.5 });

		menu.menuTimeline
			.to($menuBtnBar.eq(1), 0.3, { autoAlpha: 0, rotation: 90 }, 'start')
			.to($menuBtnBar.eq(0), 0.25, { rotation: -45, transformOrigin: 'right top', y: 1 }, 'start')
			.to($menuBtnBar.eq(2), 0.25, { rotation: 45, transformOrigin: 'right bottom', y: -0 }, 'start')
			//.to($logo, 0.6, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
			.from($menuContainer, 0.5, { autoAlpha: 0, scaleX: 0, transformOrigin: 'right top', ease: Power1.easeOut }, 'start+=0.3')
			.staggerFrom($menuLi, 1, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, '0.1', '+=0')

        menu.init();

        $menuContainer.on('menu:open', () => {
            $navContainer.replaceClass('pe-n', 'pe-a')
        });


        $menuContainer.on('menu:close', () => {
            $navContainer.replaceClass('pe-a', 'pe-n')
        });


        /*
		|
		| Menu
		|-------
		*/
        $.each($menuLinks, function(){
            const $link      = $(this);
            const linkTop    = $link.offset().top + 40
            const $subMenu   = $($link.data('sub-menu'));
            const $subMenuLi = $subMenu.find('ul li');
            const $line      = $subMenu.find('.item-line');
            let timeline     = new TimelineMax({ paused: true });

            if ($subMenuLi.length){
                timeline
                    .set($subMenu, { paddingTop: linkTop })
                    .set($line, { top: linkTop })
                    .from($subMenu, 0.5, { autoAlpha: 0, scaleX: 0, transformOrigin: 'right top', ease: Expo.easeInOut }, 'start')
                    .from($line, 0.6, {scaleX: 0, transformOrigin: 'right top', ease: Expo.easeInOut}, '-=0.2')
                    .staggerFrom($subMenuLi, 0.8, { autoAlpha: 0, y: 15, ease: Power1.easeOut }, 0.1, '-=0.2')
            }

            $link
                .on('mouseover', () => $subMenuLi.length && timeline.play().timeScale(1))
                .on('mouseleave', () => $subMenuLi.length && timeline.reverse().timeScale(2))

            $subMenu
                .on('mouseover', () => timeline.play().timeScale(1))
                .on('mouseleave', () => timeline.reverse().timeScale(2))
        });



        let detect = new MobileDetect();

        if (detect.isMobile()) {
            var subOpened = false;
            $('.mobile-display-sub-menu, .menu-item-has-children > a').on('click', function(){
                var subMenu = $(this).parent().find('ul');
                var img = $(this).parent().find('.mobile-display-sub-menu');
                if (subOpened == false) {
                    TweenMax.fromTo(subMenu, .3, { "display": "none" },{ "display":"block" })
                    TweenMax.staggerFromTo(subMenu.find('li'), 0.6, { y: 15, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: Power1.easeOut }, 0.1)
                    TweenMax.set(img, { "transform": "rotate(180deg)" });
                    subOpened = true;
                } else {
                    TweenMax.fromTo(subMenu, .3, { "display": "block", }, { "display": "none" });
                    TweenMax.staggerFromTo(subMenu.find('li'), 0.6, { y: 0, autoAlpha: 1 }, { y: 15, autoAlpha: 0, ease: Power1.easeOut }, -0.1)
                    TweenMax.set(img, { "transform": "rotate(0deg)" });
                    subOpened = false;
                }
            })
        }


        /*
        |
        | scrollAreas
        |--------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });



        /*
		|
		| Swiper full
		|--------------
        */
       /*
        |
        | Swiper Full
        |--------------
        */
        $.each($('.swiper-full'), function () {
            var $wrapper = $(this);
            var config = {
                navigation: {
                    prevEl: $(this).find('.swiper-arrow-left, .swiper-button-prev'),
                    nextEl: $(this).find('.swiper-arrow-right, .swiper-button-next')
                },
                pagination: {
                    el: $(this).find('.swiper-pagination'),
                    clickable: true
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                },
                spaceBetween: 120
            }

            if (typeof $wrapper.data('slides') !== 'undefined') {
                config.slidesPerView = $wrapper.data('slides')
            }
            if (typeof $wrapper.data('pagination') !== 'undefined') {
                config.pagination = $wrapper.data('pagination')
            }

            var swiperFull = new Swiper($(this).find('.swiper-container'), config);
        });


        /*
        |
        | back top
        |------------
        */
        $('body').on('click', '.back-top', function() {
            $('html, body').animate({scrollTop: 0}, 1000);
        });


	}
}
