/*** IMPORTS FROM imports-loader ***/
var define = false;

class InitMaps {
  constructor(container, center, zoom) {
    this.container = container;
    this.center = center;
    this.zoom = zoom;
    if (!this.container || !this.center || !this.zoom) {
      return false;
    }
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVyaWNmb3VybmllciIsImEiOiJjam12dGdvM2UwZGduM3FxdmswNmo1YjRvIn0.TpYfcYpDr1bGQMpSu_kY4Q';
    this.map = new mapboxgl.Map({
      container: this.container,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [this.center.y, this.center.x],
      zoom: this.zoom
    });
    this.markerList = [];
  }

  addMarker(data, onClick, openPopUp, dragToCard) {
    var that = this;
    var data = data;
    var geojson = {
      "id": "places",
      "type": "FeatureCollection",
      "features": data
    };


    console.log(geojson);

      that.markerList.push(geojson.features);
      // create a DOM element for the marker
      var el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = 'url("http://fapp.adveris.fr/wp-content/themes/adveris/src/img/eglise.svg")';
      el.style.backgroundSize = 'cover';
      el.style.width = geojson.features.properties.iconSize[0] + 'px';
      el.style.height = geojson.features.properties.iconSize[1] + 'px';

      if (onClick) {
        el.addEventListener('click', function() {
          //window.location.href = el.getAttribute('href');
          if (openPopUp) {
            that.openPopUp(id);
          }
          ////  that.dragtoAcard(id);
          //}
        });
      }


      // add marker to map
      new mapboxgl.Marker(el)
        .setLngLat(geojson.features.geometry.coordinates)
        .addTo(that.map);



  }


  openPopUp(id) {
    var that = this;
    var map = {};
    map.elem = document.querySelector('.js-map-card');
    map.elem.classList.add('open-map-popup');
    if (!map.elem) {
      return false;
    }
    if (map.elem.classList.contains('open-map-popup')) {
      map.elem.classList.remove('open-map-popup');
      setTimeout(function() {
        map.elem.classList.add('open-map-popup');
        that.fillPopup(map, id);
      }, 500);
    } else {
      map.elem.classList.add('open-map-popup');
      that.fillPopup(map, id);
    }
  }

  fillPopup(map, id) {
    map.titre = map.elem.querySelector('#js-map-titre');
    map.description = map.elem.querySelector('#js-map-description');
    map.button = map.elem.querySelector('#js-map-button');
    map.img = map.elem.querySelector('#js-map-img');

    map.titre.innerHTML = HomeMapData[id].properties.titre;
    map.description.innerHTML = HomeMapData[id].properties.description;
    map.button.innerHTML = HomeMapData[id].properties.button;
    map.button.setAttribute('href', HomeMapData[id].properties.link);
    map.img.setAttribute('href', HomeMapData[id].properties.link);
    map.img.setAttribute('src', HomeMapData[id].properties.image);
  }

  dragtoAcard(id) {
    var that = this;
    var container = document.querySelector('#container-map-chantier');
    var item = container.querySelector('.js-item-chantier').offsetHeight;;
    var topPos = Math.floor(id / 2) * item;
    container.scrollTop = (Math.floor(id / 2) * item);
  }


}

export default InitMaps;
