/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TweenMax, Power1 } from "gsap";

export default {
    init: (app, Scrollbar) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $selectLieux           = $('#select-lieux'),
            $selectIdMurales       = $('#select-identite-murale'),
            $realisationsList      = $('.realisations-list'),
            $realisationsListItem  = $realisationsList.find('.item-list'),
            scrollableArea         = document.querySelector('.scrollable-area'),
            $realisationsContainer = $('.real-list .container'),
            $listContainer         = $('.realisations-list')
        ;
        var slug = window.location.pathname.split('/')[2]
        $('option[value="' + slug + '"]').attr('selected', true);

        let scrollbar = null;

        let beforeLoadMore;
        let afterLoadMore;

        /*
        |
        | realisations List interaction
        |--------------------------------
        */
        let d;
        $('body').on('mouseover', '.realisations-list .item-list', function () {
            const $li = $(this).closest('li');
            const $a = $li.find('a');
            const $target = $($a.data('bloc-realisation'));

            const offset = $target.offset().top - 80;
            d = scrollbar.offset.y;

            TweenMax.to(scrollbar.contentEl, 1, { y: `-=${offset}`, ease: Power1.easeOut, onComplete: () => {
                const scrollElTranslateY = parseInt($(scrollbar.contentEl).css('transform').split(',')[5])
                //scrollbar.setPosition(0, scrollElTranslateY);
            } })
        });


        /*
        |
        | Call Ajax
        |------------
        */
        filterMural($('.filter-item.active'), true);

        $('.filter-id').on('click', '.filter-item', function() {
            let $clicked = $(this);

            filterMural($clicked, false);
        });

        function filterMural($clicked, forced) {
            let isActive = $clicked.hasClass('active');

            if (forced || !isActive) {
                $('.filter-id .filter-item').removeClass('active');
                $clicked.addClass('active');

                callAjax($clicked, $realisationsContainer, 'bloc');
            }
        }

        $selectLieux.on('change', function(){
            callAjax($(this), $realisationsContainer, 'bloc');
            // callAjax($(this), $listContainer, 'list');
        });

        // $selectIdMurales.on('change', function () {
        //     callAjax($(this), $realisationsContainer, 'bloc');
        //     callAjax($(this), $listContainer, 'list');
        // });

        function callAjax($select, $container, template) {

            if($('.load-more').data('paged') > 1) {
                $('.load-more').data('paged', 0);
            } else {
                $('.load-more').data('paged', 1);
            }
           
            $('.load-more').css('display', 'inline-block');

            let option = $('.filter-item.active');

            let optionLocation = $selectLieux.find('option:selected');

            if (optionLocation.length <= 0) {
                optionLocation = $selectLieux;
            }

            let taxonomy = option.data('taxo');
            let slug = option.data('slug');

            $container.html("")
            $('.loader').show();
            $('.load-more').hide();

            beforeLoadMore =  $('.real-item').length;

            $.ajax({
                url: $('#page-realisations').data('ajax-url'),
                type: 'POST',
                dataType: 'json',
                data: {
                    'action': 'filter_category',
                    'slug': slug,
                    'taxonomy': taxonomy,
                    'template': template,
                    'queried_term': optionLocation.attr('data-slug'),
                },
                beforeSend: () => {
                },
                success: function (response) {
                    $('.loader').hide();
                    $('.load-more').show();
                    $container.html(response);

                    let afterLoadMore = 0;

                    setTimeout( function () {
                        afterLoadMore = $('.real-item').length;

                        if(afterLoadMore - beforeLoadMore < 15) {
                            $('.load-more').css('display', 'none');
                        }

                        $('.real-item').each( (index, el) => {
                            const loc = optionLocation.attr('data-slug') ? optionLocation.attr('data-slug') : '';
                            const url = $(el).attr('href') + `?id_murale=${slug}&places=${loc}`
                            $(el).attr('href', url)
                        });                        
                    },1);  
                },
                error: function () {

                }
            })
        }


        /*
        |
        | Fake href
        |------------
        */
        $('body').on('click', '.bloc-realisation', function(e){
            const $bloc = $(this);
            const $target = $(e.target);

            if (typeof $target.data('href') !== "undefined"){
                e.preventDefault();
                window.location.href = $target.data('href');
            }
        });


        /*
        |
        | load more
        |------------
        */
        $('.load-more').on('click', function() {
            loadMore($selectLieux, $realisationsContainer, 'bloc');
        });

        function loadMore($select, $container, template) {
            let optionLocation = $select.find('option:selected');

            if (optionLocation.length <= 0) {
                optionLocation = $select;
            }

            let paged = $('.load-more').data('paged');
                paged++;

            let option = $('.filter-item.active');

            let taxonomy = option.data('taxo');
            let slug = option.data('slug');

            // $container.html("")
            $('.loader').show();
            $('.load-more').hide();


            beforeLoadMore =  $('.real-item').length;

            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    'action': 'load_more',
                    'paged': paged,
                    'slug': slug,
                    'taxonomy': taxonomy,
                    'template': template,
                    'queried_term': optionLocation.attr('data-slug'),
                },
                beforeSend: () => {
                },
                success: function (response) {
                    $('.loader').hide();
                    $('.load-more').show();
                    $('.load-more').data('paged', paged);

                    afterLoadMore = 0;

                    setTimeout( function () {
                        afterLoadMore = $('.real-item').length;

                        if(afterLoadMore - beforeLoadMore < 15) {
                            $('.load-more').css('display', 'none');
                        }

                        $('.real-item').each( (index, el) => {
                            const loc = optionLocation.attr('data-slug') ? optionLocation.attr('data-slug') : '';
                            const url = $(el).attr('href') + `?id_murale=${slug}&places=${loc}`
                            $(el).attr('href', url)
                        }); 
                    },1);                

                    $container.append(response);
                },
                error: function () {

                }
            })
        }

    }
}
