/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TimelineMax, TweenMax } from "gsap";

/*
|--------------------------------------------------------------------------------
|                                       Genius
|--------------------------------------------------------------------------------
|
| Genius is a lightweight library to manage genius slider
|
*/

/*
|
| Class
|--------
|
*/
class Genius {
    /*
    |
    | Constructor
    |--------------
    */
    constructor($wrapper, params = {}) {
        this.bindMethods();

        this.wrapper = $wrapper;
        this.params  = this.initParams(params);
        this.index   = 0;
        this.slides  = [];
        this.activeSlide;
        this.previousSlide;
        this.nextSlide;
        this.isTransitionning = false;
        this.interval;

        this.init();
    }


    /**
	|
	| bindMethods
	|-------------
    */
    bindMethods(){
        this.prev            = this.prev.bind(this);
        this.next            = this.next.bind(this);
        this.onEnterStart    = this.onEnterStart.bind(this);
        this.onEnterComplete = this.onEnterComplete.bind(this);
        this.onLeaveStart    = this.onLeaveStart.bind(this);
        this.onLeaveComplete = this.onLeaveComplete.bind(this);
    }


    /**
	|
	| initSelectors
	|----------------
    */
    initSelectors(){
        return {
            'slide'          : '.genius-slide',
            'pagination_item': '.item',
            'loader'         : $('[data-genius-loader]'),
            'loader_inner'   : $('[data-genius-loader-inner]')
        }
    }


    /**
	|
	| initParams
	|-------------
    */
    initParams(params) {
        const { autoplay, delay, mousewheel, navigation, pagination, loadbar, onUpdate, timelineEnter, timelineLeave } = params;

        return {
            'autoplay'     : this.isDefined(autoplay)      ? autoplay     : false,
            'delay'        : this.isDefined(delay)         ? delay        : 1000,
            'mousewheel'   : this.isDefined(mousewheel)    ? mousewheel   : false,
            'navigation'   : this.isDefined(navigation)    ? navigation   : false,
            'pagination'   : this.isDefined(pagination)    ? pagination   : false,
            'loadbar'      : this.isDefined(loadbar)       ? loadbar      : false,
            'onUpdate'     : this.isDefined(onUpdate)      ? onUpdate     : false,
            'timelineEnter': this.isDefined(timelineEnter) ? timelineEnter: false,
            'timelineLeave': this.isDefined(timelineLeave) ? timelineLeave: false,
            'selectors'    : this.initSelectors()
        }
    }


    /**
	|
	| Init
	|-------
    */
    init() {
        if (this.wrapperExist()) {
            this.initSlider();
            this.autoplayIsActive()   && this.handleAutoplay();
            this.navigationIsActive() && this.handleNavigation();
            this.mousewheelIsActive() && this.handleMousewheel();
            this.paginationIsActive() && this.handlePagination();
        }
    }


    /**
	|
	| initSlider
	|-------------
    */
    initSlider(){
        this.initSlides();
        this.updateSlide('init');
    }


    /**
	|
	| initSlides
	|-------------
    */
    initSlides() {
        const _this = this;

        $.each(this.wrapper.find($(this.params.selectors.slide)), function (index, value) {
            const $slide         = $(this);
            const callBackParams = [$slide, index];

            _this.slides.push({
                slide: $slide,
                timelineEnter: new TimelineMax({
                    paused          : true,
                    onStart         : _this.onEnterStart,
                    onStartParams   : callBackParams,
                    onComplete      : _this.onEnterComplete,
                    onCompleteParams: callBackParams
                }),
                timelineLeave: new TimelineMax({
                    paused          : true,
                    onStart         : _this.onLeaveStart,
                    onStartParams   : callBackParams,
                    onComplete      : _this.onLeaveComplete,
                    onCompleteParams: callBackParams
                })
            });

            if (_this.params.timelineEnter){
                _this.params.timelineEnter(_this.slides[index].slide, _this.slides[index].timelineEnter, index);
            }

            if (_this.params.timelineLeave) {
                _this.params.timelineLeave(_this.slides[index].slide, _this.slides[index].timelineLeave, index);
            }
        });
    }


    /**
	|
	| handleAutoplay
	|-----------------
    */
    handleAutoplay(){
        this.play();

        // this.wrapper
        //     .on('mouseover', () => this.stop())
        //     .on('mouseleave', () => this.play())
    }


    /**
	|
	| play
	|------
    */
    play(){
        this.interval = setInterval(() => this.next(), this.params.delay);
    }


    /**
	|
	| stop
	|-------
    */
    stop(){
        clearTimeout(this.interval);
    }


    /**
	|
	| onEnterStart
	|---------------
    */
    onEnterStart($slide, index) {
        this.isTransitionning = true;
        $slide.show();
        $slide.addClass('entering');

        if (this.paginationIsActive()) {
            this.params.pagination.find(this.params.selectors.pagination_item).removeClass('active');
            this.params.pagination.find('.target-' + index).addClass('active');
        }

        this.dispachEvent(this.wrapper, `genius:slide${index}Enter`);

        if(this.autoplayIsActive()){
            if (this.loadbarIsActive()) {

                TweenMax.fromTo(this.params.selectors.loader_inner, this.params.delay / 1000, { scaleX: 0}, { scaleX: 1, transformOrigin: 'left top', ease: Power1.easeOut })
            }
        }
    }


    /**
	|
	| onEnterComplete
	|------------------
    */
    onEnterComplete($slide, index) {
        this.isTransitionning = false;
        $slide.removeClass('entering');
        this.dispachEvent(this.wrapper, `genius:slide${index}Entered`);
    }


    /**
	|
	| onLeaveStart
	|---------------
    */
    onLeaveStart($slide, index) {
        this.isTransitionning = true;
        $slide.addClass('leaving');
        this.dispachEvent(this.wrapper, `genius:slide${index}Leave`);
    }


    /**
	|
	| onLeaveComplete
	|------------------
    */
    onLeaveComplete($slide, index) {
        $slide.removeClass('leaving');
        $slide.hide();
        this.dispachEvent(this.wrapper, `genius:slide${index}Leaved`);
    }


    /**
    |
    | handlePagination
    |-------------------
    */
    handlePagination() {
        const { pagination } = this.params;
        const _this = this;

        $.each(pagination.find($(this.params.selectors.pagination_item)), function(index, value){
            const $item = $(this);
            $item.addClass('target-' + index)
            $item.on('click', e => {
                e.preventDefault();
                _this.goTo(index)
            });
        });
    }


    /**
    |
    | handleNavigation
    |-------------------
    */
    handleNavigation(){
        const { prev, next } = this.params.navigation;

        prev.on('click', e => {
            e.preventDefault();
            this.prev();
        });
        next.on('click', e => {
            e.preventDefault();
            this.next();
        });
    }


    /**
    |
    | handleMousewheel
    |-------------------
    */
    handleMousewheel(){
        this.wrapper.bind('mousewheel DOMMouseScroll', (e, delta) => {
            e.preventDefault();
            const _delta = e.originalEvent.wheelDelta || delta;

            if (!this.isTransitionning){
                if (_delta < 0) {
                    this.next();
                } else {
                    this.prev();
                }
            }
        });
    }


    /**
    |
    | prev
    |-------
    */
    prev(){
        const newIndex = this.index > 0 ? this.index - 1 : this.slides.length - 1;

        this.callUpdate('prev', newIndex);
    }


    /**
    |
    | next
    |-------
    */
    next() {
        const newIndex = this.index < this.slides.length - 1 ? this.index + 1 : 0;

        this.callUpdate('next', newIndex);
    }


    /**
    |
    | goTo
    |-------
    */
    goTo(newIndex) {
        this.callUpdate('goTo', newIndex);
    }


    /**
    |
    | callUpdate
    |-------------
    */
    callUpdate(action, newIndex) {
        if(!this.isTransitionning){
            const leavingIndex = this.index;

            if (leavingIndex != newIndex){
                this.index = newIndex;
                this.updateSlide(action, leavingIndex);
            }
        }
    }


    /**
	|
	| updateSlide
	|--------------
    */
    updateSlide(action, leavingIndex = 0) {
        const length       = this.slides.length - 1;
        const activeIndex  = this.index;
        const prevIndex    = activeIndex > 0 ? activeIndex - 1     : length;
        const nextIndex    = activeIndex < length ? activeIndex + 1: 0;
        const $allSlides   = this.wrapper.find($(this.params.selectors.slide));
        this.leavingSlide  = this.slides[leavingIndex].slide;
        this.activeSlide   = this.slides[activeIndex].slide;
        this.previousSlide = this.slides[prevIndex].slide;
        this.nextSlide     = this.slides[nextIndex].slide;

        this.slides.map(obj => {
            obj.slide.removeClass('active previous, next');
        })

        this.activeSlide.addClass('active');

        let fired = false;
        if (action === 'init') {
            $allSlides.not(this.activeSlide).hide();
            this.dispachEvent(this.wrapper, 'genius:init', {
                genius: {
                    activeSlide: this.activeSlide,
                    index: activeIndex,
                    prevIndex: prevIndex,
                    nextIndex: nextIndex,
                }
            });

            if (this.params.timelineEnter) {
                this.slides[activeIndex].timelineEnter.play();
            }
        } else {

            if (this.params.onUpdate ){
                this.params.onUpdate(this.leavingSlide, this.activeSlide)
            }


            if (this.params.timelineLeave) {
                this.slides[leavingIndex].timelineLeave.play();

                this.wrapper.on(`genius:slide${leavingIndex}Leaved`, () => {
                    if (this.params.timelineEnter){
                        if (!fired){
                            this.wrapper.css('height', this.activeSlide.outerHeight())
                            this.slides[activeIndex].timelineEnter.play();
                            this.slides[leavingIndex].timelineEnter.seek(0).pause();
                        }
                    }
                });

                this.wrapper.on(`genius:slide${activeIndex}Entered`, () => {
                    fired = true;
                    this.slides[leavingIndex].timelineLeave.seek(0).pause();
                });
            }
            this.dispachEvent(this.wrapper, 'genius:update', {
                genius: {
                    leavingSlide: this.leavingSlide,
                    activeSlide: this.activeSlide,
                    index: activeIndex,
                    leavingIndex: leavingIndex,
                    prevIndex: prevIndex,
                    nextIndex: nextIndex,
                }
            });
        }
    }


    /**
    |
    | mousewheelIsActive
    |----------------------
    */
    mousewheelIsActive() {
        return this.params.mousewheel === true;
    }


    /**
    |
    | navigationIsActive
    |---------------------
    */
    navigationIsActive() {
        const { prev, next } = this.params.navigation;
        let isActive = false;

        if (this.isDefined(prev) && this.isDefined(next)) {
            if (this.exist(prev) && this.exist(next)) {
                isActive = true;
            }
        }

        return isActive;
    }


    /**
    |
    | paginationIsActive
    |---------------------
    */
    paginationIsActive() {
        const { pagination } = this.params;
        let isActive = false;

        if (this.isDefined(pagination)) {
            if (this.exist(pagination)) {
                isActive = true;
            }
        }

        return isActive;
    }


    /**
    |
    | autoplayIsActive
    |-------------------
    */
    autoplayIsActive() {
        return this.params.autoplay === true;
    }


    /**
    |
    | loadbarIsActive
    |-------------------
    */
    loadbarIsActive() {
        return this.params.loadbar !== false;
    }


    /**
    |
    | wrapperExist
    |---------------
    */
    wrapperExist() {
        return this.control(this.exist(this.wrapper), this.getMessage('wrapperExist'), this.wrapper);
    }


    /**
	|
	| Helper: isDefined
	|--------------------
	|
	*/
    isDefined(item) {
        return typeof item !== 'undefined';
    }


    /**
	|
	| Helper: exist
	|----------------
	*/
    exist($item) {
        return $item.length;
    }


    /**
    |
    | Helper: control
    |------------------
    */
    control(condition, message, selector = null) {
        if (!condition) {
            if (selector === null) {
                console.error(message);
            } else {
                console.error(message, selector);
            }
        }

        return condition;
    }


    /**
	|
	| Helper: dispachEvent
	|-----------------------
	*/
    dispachEvent($element, eventName, datas = null) {
        var event = $.Event(eventName);

        if (datas !== null) {
            $.each(datas, function (key, value) {
                event[key] = value
            });
        }

        $element.trigger(event);
    }


    /**
	|
	| Helper: getMessage
	|---------------------
	*/
    getMessage(messageKey, var1 = '', var2 = '') {
        var messages = {
            'wrapperExist': 'The slader wrapper (specified as 1st parameter) does not exist:',
            'prevIsDefined': 'Navigation:',
        };

        return 'Genius: ' + messages[messageKey];
    }
}

export default Genius;

