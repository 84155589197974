/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
        // VARIABLES //
        let tl = new TimelineLite();
        let contents = document.querySelectorAll(".slideAnim");
        let offsetImg = contents[0].offsetTop;
        let navItems = document.querySelectorAll(".nav__item");

        // ANIMATE //
        parallax();

        // SCROLL EVENT //
        window.addEventListener("scroll", parallax, false);

        // CLICK EVENT //
        for(let navItem of navItems) {
            navItem.addEventListener("click", slider, true);
        }

        function slider() {
            if(this.querySelector(".after") != document.querySelector(".after.isActive")) {
                // VARIABLES //
                let dataIndexActive = document.querySelector(".hero.isActive").getAttribute("data-index");
                let heroActive = document.querySelector(".hero.isActive");
                let afterActive = document.querySelector(".after.isActive");
                let contentActive = document.querySelector(".slideContent.isActive");
                let dataIndexThis = this.getAttribute("data-index");
                let elsIndexThis = document.querySelectorAll("[data-index~='"+dataIndexThis+"']");
                let heroThis = elsIndexThis[0];
                let afterThis = elsIndexThis[1].querySelector(".after");
                let contentThis = elsIndexThis[2];
                // ANIMATE //
                let tlAfter = new TimelineLite();
                if(dataIndexThis > dataIndexActive) {
                    // DISAPPEAR //
                    tlAfter.fromTo(afterActive, .5, {right: 0, left: "initial"}, {width: "0%", ease:Power1.easeInOut});
                    // APPEAR //
                    tlAfter.fromTo(afterThis, .5, {right: "initial", left: 0}, {width: "100%", ease:Power1.easeInOut}, "-=.5");
                } else {
                    // DISAPPEAR //
                    tlAfter.fromTo(afterActive, .5, {right: "initial", left: 0}, {width: "0%", ease:Power1.easeInOut});
                    // APPEAR //
                    tlAfter.fromTo(afterThis, .5, {right: 0, left: "initial"}, {width: "100%", ease:Power1.easeInOut}, "-=.5");
                }
                // REMOVE //
                afterActive.classList.remove("isActive");
                heroActive.classList.remove("isActive");
                contentActive.classList.remove("isActive");
                heroActive.classList.remove("slideAnim");
                let contentsActiveAnim = contentActive.querySelectorAll(".slideAnim");
                for(let contentActiveAnim of contentsActiveAnim) {

                    contentActiveAnim.classList.remove("slideAnim");
                }
                // ADD //
                afterThis.classList.add("isActive");
                heroThis.classList.add("isActive");
                contentThis.classList.add("isActive");
                heroThis.classList.add("slideAnim");
                let contentsThisAnim = contentThis.querySelectorAll(".item");
                for(let contentThisAnim of contentsThisAnim) {
                    contentThisAnim.classList.add("slideAnim");
                }
                // SLIDE //
                contents[0].setAttribute('animating', false);
                contents = document.querySelectorAll(".slideAnim");
                // ANIMATE //
                parallax();
            }
        }

        function offset(el) {
            let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            return { top: rect.top + scrollTop, left: rect.left + scrollLeft, height: rect.height }
        }

        function parallax() {
            for(let content of contents) {
                // VARIABLES //
                let imgContent, offsetContent, starterParallax, starterSticky, offsetSticky = 0;
                if(content.querySelector(".container_img")) {
                    imgContent = content.querySelector(".container_img");
                    offsetContent = offset(imgContent);
                    starterParallax = offsetContent.top -offsetImg -window.innerHeight;
                } else {
                    offsetContent = offset(content);
                }
                if(content == document.querySelector(".identite__navigation")) {
                    let identite = content;
                    offsetSticky = offset(identite);
                    starterSticky = offsetSticky.top -offsetImg -offsetSticky.height;
                }
                let offsetHero = offset(document.querySelector(".identite__hero"));
                let stopSticky = offsetHero.top +document.querySelector(".identite__hero").offsetHeight;
                let halfWindow = window.innerHeight *3 /4;
                let starterAnimate = offsetContent.top -halfWindow;
                let translate = - (window.pageYOffset -offsetContent.top) /10;

                // DATA //
                if(!content.getAttribute("animating")) {
                    // ATTRIBUTE //
                    content.setAttribute('animating', false);
                }
                // STICKY //
                if(content == document.querySelector(".identite__navigation")) {
                    console.log("Window : "+window.pageYOffset)
                    console.log("Sticky : "+starterSticky)
                    if(window.pageYOffset >= starterSticky) {
                        console.log("ok")
                        content.classList.add("sticky")
                        document.querySelector(".header").classList.add("header__dark")
                    }
                    if(content.classList.contains("sticky") == true) {
                        if(window.pageYOffset < stopSticky) {
                            content.classList.remove("sticky")
                            document.querySelector(".header").classList.remove("header__dark")
                        }
                    }
                }
                // PARALLAX //
                if(window.pageYOffset >= starterParallax ) {
                    if(content == contents[1]) {
                        translate = - (window.pageYOffset -offsetContent.top +offsetImg) /6;
                    } else if(content == contents[2]) {
                        translate = - (window.pageYOffset -offsetContent.top) /18;
                    } else if((content == contents[5]) || (content == contents[8])) {
                        translate = - (window.pageYOffset -offsetContent.top) /8;
                    } else if((content == contents[7]) || (content == contents[6])) {
                        translate = - (window.pageYOffset -offsetContent.top) /6;
                    }
                    // MOVE //
                    imgContent.style.transform =  "translateY("+translate+"px)";
                }
                // ANIMATE CONTENT //
                if((window.pageYOffset >= starterAnimate) || (content == contents[1])) {
                    // APPEAR //
                    if((content.getAttribute("animating") == "false")) {
                        animateContent(content)
                    }
                }
            }
        }

        function animateContent(content) {
            // Timeline
            let img, imgMask, date, title, titleMask, text, cta, swiper, after;
            if(content.querySelector(".container_img")) {
                img = content.querySelector(".imagefit");
                imgMask = content.querySelector(".container_img .mask");
            }
            if(content.querySelector(".date")) {
                date = content.querySelector(".date");
            }
            if(content.querySelector(".container_title")) {
                title = content.querySelector(".title");
                titleMask = content.querySelector(".container_title .mask");
            }
            if(content.querySelector(".content_text")) {
                text = content.querySelector(".content_text");
            }
            if(content.querySelector(".cta")) {
                cta = content.querySelector(".cta");
            }
            if(content.querySelector(".swiper__identite")) {
                swiper = document.querySelectorAll(".swiper__identite");
                after = document.querySelector(".after.isActive");
            }

            // Data
            content.setAttribute('animating', true);
            
            // Animation
            if(content.querySelector(".container_img")) {
                tl.fromTo(imgMask, 1.25, {width: "100%", x: "0%", skewX: "30deg", scale: 3}, {x: "300%", ease:Power1.easeInOut});
                tl.fromTo(img, .5, {scale: 1.25}, {scale: 1, ease:Power1.easeInOut, clearProps:"x"}, "-=.25");
            }
            if(content.querySelector(".container_title")) {
                tl.fromTo(titleMask, .5, {width: "0%", right:0}, {width: "100%", ease:Power1.easeInOut}, "-=.25");
                tl.fromTo(title, .25, {autoAlpha: 0, right:0}, {autoAlpha: 1, ease:Power1.easeInOut});
                tl.to(titleMask, .75, {width: "0%", ease:Power1.easeInOut}, "-=.25");
            }
            if(content.querySelector(".swiper__identite")) {
                tl.fromTo(swiper, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut});
                tl.to(after, .5, {width: "100%", ease:Power1.easeInOut});
            }
            if(content.querySelector(".content_text")) {
                tl.fromTo(text, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut}, "-=.75");
            } 
            if(content.querySelector(".date")) {
                tl.fromTo(date, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut}, "-=.25");
            }
            if(content.querySelector(".cta")) {
                tl.fromTo(cta, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut}, "-=.5");
            }
        }
    }
}
