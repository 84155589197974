/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, ScrollMagic) => {
		/*
		|
		| Constants
		|------------
		|
		*/
        const
			$body = $('body')
        ;

        /*
		|
		| On Load Animations
		|--------------------
		|
        */
        const
            onLoadTimeline           = new TimelineMax({ paused: true }),
            $onLoadTimelineContainer = $('[data-onload-timeline]'),
            $onLoadTimelineItems     = $onLoadTimelineContainer.find('[data-timeline-item]')
        ;

        $.each($onLoadTimelineItems, function(k, v){
            const $item = $(this);
            const type = $item.data('timeline-item');
            const defStart = k === 0 ? 'start' : '-=0.6';
            const start = $item.data('start') ? $item.data('start') : defStart;

            /*
            | Split Text entrance
            |----------------------
            */
            if (type === 'splittext.title.xl'){
                const splitItem = new SplitText($item, { type: 'words, chars' });
                onLoadTimeline.staggerFrom(splitItem.chars, 1, { y: 10, autoAlpha: 0, ease: Sine.easeOut }, 0.025, start)
            }

            /*
            | FadeInUp sm
            |--------------------
            */
            if (type === 'fadeInUp.sm'){
                onLoadTimeline.from($item, 0.8, { y: 15, autoAlpha: 0, ease: Power1.easeOut }, start)
            }

            /*
            | FadeInUp sm
            |--------------------
            */
            if (type === 'overlayed-img') {
                onLoadTimeline.from($item, 0.8, { scaleX: 0, transformOrigin: 'left top', ease: Expo.easeInOut }, start)
                onLoadTimeline.to($item.find('.item-overlay'), 0.8, { scaleX: 0, transformOrigin: 'right top', ease: Expo.easeInOut }, '-=0.3')
            }
        });

        $body.on('loaderAlmostEnd', () => console.log('end'));
        $body.on('loaderAlmostEnd', () => onLoadTimeline.play());


		/*
		|
		| On Scroll Animations
		|-----------------------
		|
		*/
		let controller = new ScrollMagic.Controller();
		let scenes = [];
		let sceneConf = [];
		const $timelines = $('[data-timeline]');

		$.each($timelines, function(){
			const $timeline = $(this);
			const timeline  = new TimelineMax();

			/*
			| Generate Timeline
			|--------------------
			*/
			$.each($timeline.find('[data-timeline-item]'), function(k, v){
				const $item    = $(this);
				const stagger  = '[data-stagger-item]';
				const type     = $item.data('timeline-item');
				const defStart = k === 0 ? 'start': '-=0.6';
				const start    = $item.data('start') ? $item.data('start') : defStart;


				/*
				| Split Text md
				|----------------------
				*/
				if (type === 'splittext.md'){
					const splitItem = new SplitText($item, { type: 'words, chars' });

					timeline.staggerFrom(splitItem.chars, 0.6, { y: 5, autoAlpha: 0, ease: Power1.easeOut }, 0.008, start)
				}


                /*
				| FadeInUp Parallax
				|--------------------
				*/
				if (type === 'fadeInUp.parallax.sm'){
					timeline.from($item, 0.8, { y: 100, autoAlpha: 0, ease: Sine.easeOut }, start)
				}

				/*
				| FadeInUp Parallax
				|--------------------
				*/
				if (type === 'fadeInUp.parallax'){
					timeline.from($item, 1.2, { y: 150, autoAlpha: 0, ease: Power1.easeOut }, start)
                }


			});

			sceneConf.push({ 'triggerElement': $timeline, 'timeline': timeline, 'hook': $timeline.data('hook') });
		});

		/*
		| Generate Scenes
		|--------------------
		*/
		sceneConf.map(function(conf){
			scenes.push(createScene(conf))
		});

		function createScene(conf) {
			return new ScrollMagic.Scene({
				'triggerElement': conf.triggerElement,
				'triggerHook'   : app.isDefined(conf.hook) ? conf.hook: 'onEnter'
			}).setTween(conf.timeline)
			//.addIndicators()
		}

		controller.addScene(scenes);

	}
}

