/*** IMPORTS FROM imports-loader ***/
var define = false;

import { TweenMax } from "gsap";

export default {
    init: (app, Swiper) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $header              = $('header.header'),
            $swiperRealisation   = $('.swiper-realisation'),
            $swiperContainer     = $swiperRealisation.find('.swiper-container'),
            $swiperControls      = $swiperRealisation.find('.swiper-controls'),
            $btnDescription      = $('.btn-display-description'),
            $blocDescription     = $('.bloc-description'),
            $btnCloseDescription = $blocDescription.find('.item-close')
        ;

        /*
        |
        | Swiper
        |---------
        */
        const interleaveOffset = 0.5;
        let swiper = new Swiper($swiperContainer, {
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            autoHeight: true,
            navigation: {
                nextEl: $swiperControls.find('.btn-next'),
                prevEl: $swiperControls.find('.btn-prev')
            },
            on: {
                progress: function () {

                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {

                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        $(swiper.slides[i]).find(".inner-slide").css('transform', "translate3d(" + innerTranslate + "px, 0, 0)");
                    }
                },
                touchStart: function () {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        $(swiper.slides[i]).css('transition', "");
                    }
                },
                setTransition: function (speed) {
                    const swiper = this;

                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        $(swiper.slides[i]).find(".inner-slide").css('transition', speed + "ms");

                    }
                }
            }
        });


        /*
        |
        | Swiper controls
        |----------------------
        */
        // $swiperControls.find('.btn-pause').on('click', e => {
        //     e.preventDefault()
        //     swiper.autoplay.stop();
        // })

        var paused = false;
        $swiperControls.find('.playpause').on('click', e => {
            if (paused) {
                e.preventDefault()
                swiper.autoplay.start();
                $('.btn-pause')[0].style.visibility = "visible";
                $('.btn-play')[0].style.visibility = "hidden";
                paused = false;
            } else{
                e.preventDefault()
                swiper.autoplay.stop();
                $('.btn-pause')[0].style.visibility = "hidden";
                $('.btn-play')[0].style.visibility = "visible";
                paused = true;
            }

        })


        /*
        |
        | Display description
        |----------------------
        */
        const timelineDescription = new TimelineMax({ paused: true });

        timelineDescription
            .from($blocDescription, 0.4, { autoAlpha: 0, ease: Power2.easeOut }, 'start')
            .from($blocDescription.find('.item-title'), 0.6, { x: 30, autoAlpha: 0, ease: Power1.easeOut }, '-=0.2')
            .from($blocDescription.find('.item-cols-container'), 0.8, { scaleY: 0, transformOrigin: 'top left', ease: Expo.easeInOut }, '-=0.5')
            .staggerFrom($blocDescription.find('.item-cols-container .item-col'), 0.8, { y: 15, autoAlpha: 0, ease: Power1.easeOut }, 0.15, '-=0.2')

        $btnDescription.on('click', function(e){
            e.preventDefault();

            if (timelineDescription.totalProgress() > 0){
                timelineDescription.reverse().timeScale(1.5);
            } else {
                timelineDescription.play().timeScale(1);
            }
        });

        $btnCloseDescription.on('click', function(){
            timelineDescription.reverse().timeScale(1.5);
        });

        $('.bloc-description').on('click', function(e){
            e.stopPropagation();

            if (this == e.target) {
                timelineDescription.reverse().timeScale(1.5);
            }
        });

        $('body').on('keyup', function(e) {
            if (e.keyCode == 27) {
                timelineDescription.reverse().timeScale(1.5);
            }
        });



        /*
        |
        | NEXT PROJECT
        |----------------------
        */

        const url_params = window.location.href.split('/').pop();
        const url = $('.btn-next-project').attr('href') + '&' + url_params.substring(1);
        $('.btn-next-project').attr('href', url);

    }
}
