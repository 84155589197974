/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Plyr) => {
        const player = new Plyr('#duo-video', {
            hideControls: true
        });
    }
}
