/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {

        const $container = $('.news-list');

        /*
        |
        | load more
        |------------
        */
        $('.load-more').on('click', function() {
            loadMorePosts();
        });

        function loadMorePosts() {
            let paged = $('.load-more').data('paged');
                paged++;

            // $container.html("")
            $('.loader').show();
            $('.load-more').hide();

            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    'action': 'load_more_posts',
                    'paged': paged,
                },
                success: function (response) {
                    $('.loader').hide();
                    $('.load-more').show();
                    $('.load-more').data('paged', paged);

                    $('.news-list').append(response);
                },
                error: function () {

                }
            })
        }
    }
}
