/*** IMPORTS FROM imports-loader ***/
var define = false;

class OpenObject {
  constructor(trigger,closer,div,classe) {
    this.trigger = [trigger];
    this.closer = closer;
    this.div = div;
    this.classe = classe;
    if ( !trigger || !div ) {
        return false;
    }
    this.open();
  }

  open(){
    var that = this;
    that.trigger.forEach(function(item){
      item.onclick = () => {
        that.div.classList.toggle(that.classe);
        item.classList.toggle(that.classe);
      }
    });
  }


}

export default OpenObject;

