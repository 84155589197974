/*** IMPORTS FROM imports-loader ***/
var define = false;

import { library, dom } from '@fortawesome/fontawesome-svg-core/index';
import { faWordpress, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons'; // prefix: fab
import {
    faArrowRight as fasArrowRight,
    faPlay as fasPlay,
    faPause as fasPause,
    faForward as fasForward,
    faBackward as fasBackward,
} from '@fortawesome/pro-solid-svg-icons'; // prefix: fas
import { faFire as farFaFire } from '@fortawesome/pro-regular-svg-icons'; // prefix: far
import {
    faInfoCircle as falInfoCircle,
    faTimes as falTimes,
    faArrowRight as falArrowRight,
    faPlay as falPlay,
    faPause as falPause,
    faChevronRight as falChevronRight,
    faChevronLeft as falChevronLeft
 } from '@fortawesome/pro-light-svg-icons'; // prefix: fal

library.add(
    faWordpress,
    faInstagram,
    faPinterest,
    falInfoCircle,
    falTimes,
    farFaFire,
    falArrowRight,
    fasArrowRight,
    fasForward,
    fasBackward,
    falChevronLeft,
    falChevronRight,
    fasPlay,
    fasPause,
    falPlay,
    falPause,
)
dom.watch();
