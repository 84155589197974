/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
        // Variables
        

        // Animate
        //animateContent(main);

        // Scroll appear


        
    }
}
