/*** IMPORTS FROM imports-loader ***/
var define = false;

class SliderHome {
  constructor(slider) {
    if (!slider) {
      return false;
    }
    this.slider = slider;
    this.run = true;
    this.duration = 8000;

    this.mainInterval = null;
    this.endInterval = null;
    this.iterator = null;
    this.initSlider();
  }

  initSlider() {
    var that = this;
    that.items = that.slider.querySelectorAll('.js_slider_item');
    that.anchors = that.slider.querySelectorAll('.js_slider_anchor');
    if (that.items.length != that.anchors.length) {
      return false;
    } else {
      that.iterator = that.items.length - 1;
      that.showSlide(0);
      that.iteration();
      that.interval();
      that.handleMenu();
    }
  }

  iteration() {
    var that = this;
    if (that.iterator == that.items.length - 1) {
      that.iterator = 0;
    } else {
      that.iterator++;
    }
  }

  interval() {
    var that = this;
    let counter = that.duration/1000;
    that.mainInterval = setInterval(function() {
      that.iteration()
      that.showSlide(that.iterator);
      counter = that.duration/1000;
    }, that.duration);
    that.endInterval = setInterval(function() {
      counter= counter-.5;
      if(counter == .5) {
        that.hideSlide(that.iterator);
      }
    }, 500);
  }

  showSlide(item) {
    var that = this;

    let tl = new TimelineLite();
    let img = that.items[item].querySelectorAll('.presentation__img .mask');
    let title = that.items[item].querySelectorAll('.container_title .mask');
    let text = that.items[item].querySelectorAll('.container_text');
    let cta = that.items[item].querySelectorAll('.cta__light');

    that.items.forEach(function(element) {
      if (element.classList.contains('isActive')) {
        //tl.reverse(5);
        element.classList.remove('isActive');
      }
    });
    that.anchors.forEach(function(element) {
      if (element.classList.contains('isActive')) {
        element.classList.remove('isActive');
      }
    });

    tl.fromTo(img, .75, {width: '100%', height:'100%', right:0}, {width: 0, ease:Power1.easeInOut});
    tl.fromTo(title, .75, {width: '100%', height:'100%', right:0}, {width: 0, ease:Power1.easeInOut}, "-=.5");
    tl.fromTo(text, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut}, "-=.5");
    tl.fromTo(cta, .5, {y:25, autoAlpha: 0}, {y:0, autoAlpha: 1, ease:Power1.easeInOut}, "-=.5");
    
    that.items[item].classList.add('isActive');
    that.anchors[item].classList.add('isActive');
  }

  hideSlide(item) {
    var that = this;

    let tl = new TimelineLite();
    let img = that.items[item].querySelectorAll('.presentation__img .mask');
    let title = that.items[item].querySelectorAll('.container_title .mask');
    let text = that.items[item].querySelectorAll('.container_text');
    let cta = that.items[item].querySelectorAll('.cta__light');

    tl.fromTo(cta, .5,  {y:0, autoAlpha: 1}, {y:25, autoAlpha: 0, ease:Power1.easeInOut});
    tl.fromTo(text, .5, {y:0, autoAlpha: 1}, {y:25, autoAlpha: 0, ease:Power1.easeInOut}, "-=.5");
    tl.fromTo(title, .5, {width: 0}, {width: '100%', height:'100%', right:0, ease:Power1.easeInOut}, "-=.5");
    tl.fromTo(img, .5, {width: 0}, {width: '100%', height:'100%', right:0, ease:Power1.easeInOut}, "-=.5");
    
    that.items[item].classList.add('isActive');
    that.anchors[item].classList.add('isActive');
  }

  switchToSlide(index){
    var that =this;
    clearInterval(that.mainInterval);
    clearInterval(that.endInterval);
    that.hideSlide(that.iterator);
    setTimeout(function() {
      that.iterator = index;
      that.showSlide(index);
      that.interval();
    }, 500);
  }

  handleMenu() {
    var that = this;
    that.anchors.forEach(function(element,key) {
      element.setAttribute('index',key);
    });
    that.anchors.forEach(function(element) {
      element.onclick = function() {
        that.switchToSlide(this.getAttribute('index'));
      }
    });
  }


}

export default SliderHome;

