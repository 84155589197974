/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs & dependancies
|--------------------------------
*/
import Swiper from 'swiper/dist/js/swiper.min';
import 'bootstrap/dist/js/bootstrap.min.js';
require('../lib/iziModal/js/iziModal.js')($); //désolé

/*
|
| Importing Animations libs
|----------------------------
*/
// import { TweenMax } from "gsap/TweenMax";
// import TimelineMax from "gsap/TimelineMax";
import Draggable from "gsap/draggable";
import SplitText from "../lib/gsap-pro/SplitText";
// import ScrollTo from "gsap/ScrollToPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import '../lib/ias/jquery-ias.js';
import Plyr from 'plyr';
import Scrollbar from 'smooth-scrollbar';


/*
|
| Custom libs
|--------------
*/
import Genius from "./components/genius";
import "./components/gsap-slider";
import OpenObject from "./components/utils/openMenu";
import InitMaps from "./components/maps/init";
import SliderHome from "./components/sliderHome/sliderHome";
import MobileDetect from './components/mobile-detect.js';

/*
|
| Importing Utils
|----------------------------
*/
import './utils/fa';
import Router from './utils/router.js';

/*
|
| Importing Components
|----------------------
*/
import * as app from './components/global.js';
import Menu from './components/menu.js';
import customGoogleMap from './components/custom-google-map.js';

/*
|
| Importing App files
|----------------------
*/
import main from './pages/main.js';
import home from './pages/home.js';
import singleRealisation from './pages/single-realisation.js';
import realisations from './pages/realisations.js';
import contact from './pages/contact.js';
import clients from './pages/clients.js';
import blog from './pages/blog.js';
import article from './pages/article.js';
import animation from './pages/animation.js';
import duo from './pages/duo.js';
// import univers from './pages/univers.js';
// import presse from './pages/presse.js';
import identite from './pages/identite.js';
// import realisation from './pages/realisation.js';
/*
|
| Routing
|----------
*/
const routes = new Router([
	{
		'file': main,
		'dependencies': [app, Menu, OpenObject, SliderHome, InitMaps, Swiper, Scrollbar, MobileDetect]
    },
    {
        'file': home,
        'dependencies': [app, Genius, Swiper],
        'resolve': '#page-home'
    },
	{
		'file': singleRealisation,
		'dependencies': [app, Swiper],
		'resolve': '#page-single-realisation'
    },
    {
        'file': realisations,
        'dependencies': [app, Scrollbar],
        'resolve': '#page-realisations'
    },
    {
        'file': contact,
        'dependencies': [app, customGoogleMap],
        'resolve': '#page-contact'
    },
    {
        'file': clients,
        'dependencies': [app],
        'resolve': '#page-clients'
	},
	{
        'file': blog,
        'dependencies': [app],
        'resolve': '#page-blog'
	},
	{
        'file': article,
        'dependencies': [app],
        'resolve': '#page-article'
    },
    {
        'file': animation,
        'dependencies': [app, ScrollMagic]
    },
	{
        'file': duo,
        'dependencies': [app, Plyr],
        'resolve': '#page-duo'
	},
	// {
    //     'file': univers,
    //     'dependencies': [app, SubZero],
    //     'resolve': '.container__univers'
	// },
	// {
    //     'file': presse,
    //     'dependencies': [app, SubZero],
    //     'resolve': '.container__presse'
	// },
	{
        'file': identite,
        'dependencies': [app],
        'resolve': '#page-identite'
	},
	// {
    //     'file': realisation,
    //     'dependencies': [app, SubZero],
    //     'resolve': '.container__realisations'
	// },
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

